<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<div
			class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					v-if="$can('gift_orders', 'create')"
					variant="success"
					class="float-right"
					@click="$router.push({ name: 'NewGiftOrder'});">
					{{ translate('create') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						v-if="showUserFilter()"
						class="col-md-4 col-sm-4 col-12">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,pre_distributor,customer,affiliate'}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div class="col-12 col-md-4 col-sm-4">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
					<div class="col-12 col-md-4 col-sm-4">
						<div
							class="form-group">
							<label
								for="invoiceId"
								class="label">{{ translate('invoice_id') }}</label>
							<input
								id="invoiceId"
								v-model="filters.invoiceId"
								:placeholder="translate('type_invoice')"
								type="text"
								name="invoiceId"
								class="form-control">
						</div>
					</div>
					<div
						v-if="!showUserFilter()"
						class="col-md-4 col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="status"
								class="label">{{ translate('order_status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div class="row">
					<div
						v-if="showUserFilter()"
						class="col-md-4 col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="status"
								class="label">{{ translate('order_status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-4 col-sm-4 col-12">
						<div
							v-if="countries !== null"
							class="form-group">
							<label
								for="country"
								class="label">{{ translate('country') }}</label>
							<select
								id="country"
								v-model="filters.country"
								:disabled="!countries.length || staffCountry"
								name="country"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="'' || undefined">
										{{ translate('all') }}
									</option>
									<template v-if="countries.length">
										<option
											v-for="country in countries"
											:key="country.attributes.code"
											:value="country.attributes.code">
											{{ translate(country.attributes.code.toLowerCase()) }}
										</option>
									</template>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0">
							{{ translate('order_id') }}
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer">-->
							<!--@click="sortByField('id')">-->
							{{ translate('invoice_id') }}
							<!--<sort field="id" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('user_id')">-->
							{{ translate('user_id') }}
							<!--<sort field="user_id" />-->
						</th>
						<th
							v-if="admin.includes($user.details().type)"
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('username')">-->
							{{ translate('username') }}
							<!--<sort field="username" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('ship_to')">-->
							{{ translate('ship_to') }}
							<!--<sort field="ship_to" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('tracking_number')">-->
							{{ translate('tracking_number') }}
							<!--<sort field="tracking_number" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('tracking_status')">-->
							{{ translate('tracking_status') }}
							<!--<sort field="tracking_status" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('payment_method')">-->
							{{ translate('payment_method') }}
							<!--<sort field="payment_method" />-->
						</th>
						<th
							class="border-top-0 text-right">
							{{ translate('total') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('order_status') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('country') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('date_time') }}
						</th>
						<th class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							{{ item.id }}
							<i
								v-if="item.attributes.is_fraud"
								v-b-tooltip.hover
								:title="translate('fraudulent_order')"
								class="fa fa-info-circle"
								style="margin-left:5px; margin-top:2px; color:#f86c6b" />
							<i
								v-if="item.attributes.is_invisible"
								v-b-tooltip.hover
								:title="translate('invisible_order')"
								class="fa fa-eye-slash"
								style="margin-left:5px; margin-top:2px; color:gray" />
						</td>
						<td class="align-middle">
							{{ item.attributes.invoice_id }}
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							<span
								v-if="item.attributes.user_type === distributor"
								class="badge badge-primary pointer"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
								{{ item.attributes.user_id }}
							</span>
							<span
								v-else
								class="badge badge-primary">
								{{ item.attributes.user_id }}
							</span>
						</td>
						<td
							v-if="admin.includes($user.details().type)"
							class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td
							class="align-middle">
							{{ item.attributes.ship_to }}
						</td>
						<td
							class="align-middle text-left">
							<template v-if="(item.attributes.tracking.length != 0)">
								<template
									v-if="shippingMethodsLinkable.includes(item.attributes.tracking['carrier_code'])">
									<a
										:href="shippingMethodsLinks[item.attributes.tracking['carrier_code']](item.attributes.tracking['tracking_number'])"
										target="_blank">
										{{ item.attributes.tracking['tracking_number'] }}
									</a>
								</template>
								<template v-else>
									{{ item.attributes.tracking['tracking_number'] }}
								</template>
							</template>
						</td>
						<td
							class="align-middle">
							{{ translate(item.attributes.tracking_status) }}
						</td>
						<td
							class="align-middle">
							<span v-if="item.attributes.wallet_split">{{ translate('cashondelivery') }} + </span> {{ translate(item.attributes.payment_method) }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_amount }}
						</td>
						<td class="align-middle">
							{{ translate(item.attributes.status.toLowerCase()) }}
						</td>
						<td
							class="align-middle">
							<img
								v-b-tooltip.hover
								class="ml-3"
								:title="translate(item.attributes.country.toLowerCase())"
								:src="require(`@/assets/images/common/countries/select/${item.attributes.country.toUpperCase()}.png`)"
								:alt="translate(item.attributes.country.toLowerCase())">
						</td>
						<td class="align-middle">
							{{ item.attributes.date.date ? $moment(item.attributes.date.date).format(dateFormat) : $moment(item.attributes.date).format(dateFormat) }}
						</td>
						<td class="align-middle text-center">
							<b-button
								v-if="validateBinaryButton(item)"
								v-b-tooltip.hover
								:title="translate('view_binary_tree')"
								class="btn mx-1 bg-primary-alt"
								@click="$router.push({ name: 'BinaryTree', params: { userId: item.attributes.user_id } })">
								<i
									class="fa fa-network-wired"
									aria-hidden="true" />
							</b-button>
							<router-link
								v-if="item.attributes.wallet_address !== ''"
								:to="{ name: 'CryptoPaymentsDetails', params: { wallet_address: item.attributes.wallet_address } }"
								target="_blank">
								<b-button
									v-b-tooltip.hover
									:title="translate('crypto_payment_details')"
									class="btn mx-1 bg-primary-alt">
									<i
										class="fab fa-bitcoin"
										aria-hidden="true" />
								</b-button>
							</router-link>
							<b-button
								v-if="!admin.includes($user.details().type) && item.attributes.can_retry_payment"
								v-b-tooltip.hover.top
								:disabled="retryingPayment"
								:title="translate('retry_payment')"
								class="btn mx-1 bg-primary-alt"
								@click="retryPayment(item.id)">
								<i
									v-if="orderRetried !== item.id"
									class="fa fa-refresh"
									aria-hidden="true" />
								<template v-else>
									<i class="fa fa-fw fa-spinner fa-pulse" />
								</template>
							</b-button>
							<b-button
								v-b-tooltip.hover
								:title="translate('view_details')"
								class="btn mx-1 bg-primary-alt"
								@click="viewDetails(item.id)">
								<i
									class="fa fa-eye"
									aria-hidden="true" />
							</b-button>
							<router-link
								v-if="!admin.includes($user.details().type) && item.attributes.can_payment_method_change"
								:to="{ name: $route.name === 'OrdersPickup' ? 'PSChangePaymentMethodMain' : 'ChangePaymentMethodMain', params: { order_id: item.id } }">
								<b-button
									v-b-tooltip.hover.top
									:title="translate('change_payment_method')"
									class="btn mx-1 bg-primary-alt">
									<i
										class="fa fa-money-bill-wave"
										aria-hidden="true" />
								</b-button>
							</router-link>
							<b-button
								v-if="$can('orders', 'invoice') && item.attributes.invoice"
								v-b-tooltip.hover
								:title="translate('download')"
								:disabled="loading"
								class="btn mx-1 bg-primary-alt"
								@click="download(item.id, item.attributes.invoice_id)">
								<i
									class="fa fa-download"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>

import { PAGINATION } from '@/settings/RequestReply';
import {
	Grids, Tooltip, Users, Countries, PaymentMethods, TrackingStatus,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import DataFilter from '@/components/DataFilter';
import { YMDHMS_FORMAT, DATE_RANGES as dateRanges, V_CALENDAR_CONFIG } from '@/settings/Dates';
import Order from '@/util/Order';
import { SEARCH_USERS } from '@/config/endpoint';
import {
	distributor, customerRoles, superAdmin, admin,
} from '@/settings/Roles';
import { ORDER_STATUSES as statuses } from '@/settings/Statuses';
import { RegisterAsCustomer } from '@/router/Modules/Pages';
import Country from '@/util/Country';
import ExternalPayments from '@/util/Purchase';
import { SHIPPING_METHODS_LINKS, SHIPPING_METHODS_LINKABLE } from '@/settings/Shipping';
import { GIFT_ORDER_VALID_COUNTRIES } from '@/settings/GiftOrders';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'Orders',
	messages: [Grids, Tooltip, Users, Countries, PaymentMethods, TrackingStatus],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			invoice: new Order(),
			orders: new Order(),
			alert: new this.$Alert(),
			countryData: new Country(),
			endpoint,
			superAdmin,
			statuses,
			dateFormat: YMDHMS_FORMAT,
			dateRanges,
			calendarConfig: V_CALENDAR_CONFIG,
			copied: false,
			registerCustomerLink: `${document.location.origin}/${RegisterAsCustomer.path}`,
			distributor,
			customerRoles,
			admin,
			shippingMethodsLinks: SHIPPING_METHODS_LINKS,
			shippingMethodsLinkable: SHIPPING_METHODS_LINKABLE,
			externalPayments: new ExternalPayments(),
			orderRetried: 0,
			orderId: null,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		errors() {
			return this.orders.data.errors;
		},
		pagination() {
			return this.orders.data.pagination;
		},
		data() {
			try {
				const { data } = this.orders.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		countries() {
			try {
				return this.countryData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		retryingPayment() {
			return this.externalPayments.data.loading;
		},
		staffCountry() {
			if (this.admin.includes(this.$user.details().type)) {
				return this.$user.details().country !== undefined;
			}
			return false;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	watch: {
		copied(value) {
			if (value) {
				setTimeout(() => { this.copied = false; }, 4000);
			}
		},
	},
	mounted() {
		if (distributor.includes(this.$user.details().type) && !GIFT_ORDER_VALID_COUNTRIES.includes(this.$user.details().country.iso_code_2)) {
			this.$router.push({ name: 'Home' });
			return;
		}

		if (this.$route.params.distributorId) this.$route.query.userId = this.$route.params.distributorId;
		this.orders.getGiftOrders();
		this.countryData.getCountries({ giftOrders: true });
		this.filters.country = this.$route.query.country;

		if (this.$refs.orderId) {
			this.$refs.orderId.focus();
		}
	},
	methods: {
		download(orderId, invoiceId) {
			const config = {
				allowEscapeKey: false,
				allowOutsideClick: false,
			};

			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), config);
			this.invoice.getInvoice(orderId).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] });
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('invoice_id')} ${invoiceId}.pdf`);
				this.$el.appendChild(link);
				link.click();
				this.alert.close();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.orders.getGiftOrders(options);
		},
		viewDetails(orderId) {
			this.$router.push({ name: 'OrdersDetails', params: { order_id: orderId } });
		},
		goToDetails() {
			try {
				const orderId = this.orderId.trim();
				if (orderId) {
					this.viewDetails(orderId);
					this.orderId = '';
				}
			} catch (error) {
				// do nothing
			}
		},
		showUserFilter() {
			return this.admin.includes(this.$user.details().type);
		},
		validateBinaryButton(item) {
			return this.admin.includes(this.$user.details().type) && item.attributes.type === distributor;
		},
		retryPayment(orderId) {
			// const options = {
			// 	idempotency_id: this.createUuid(),
			// 	// eslint-disable-next-line no-underscore-dangle
			// 	sift_session_id: window._sift_session_id,
			// };
			this.orderRetried = orderId;
			this.externalPayments.retryExternalPayment(orderId)
				.then((response) => {
					window.location.href = response.response.redirect_url;
				}).catch(() => this.alert.toast('error', this.translate('default_error_message')))
				.finally(() => { this.orderRetried = 0; });
		},
	},
};
</script>
